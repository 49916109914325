import { useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import { Menu } from '@mui/material';
import fixuplogo from 'assets/FIXUP-LOGO.svg';
import { StyledMenuItem } from 'components/common/styled-menu-item';
import { PricingButton } from '../styled';
import PricingMenuDropdown from './pricing-menu/pricing-menu-dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import RegionButton from './region-button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const PagesWithWhiteHeader = ['/reviews', '/servicios', '/reparar'];

const DesktopHeader = () => {
  const [buttonHover, setButtonHover] = useState(false);
  const [anchorElPricing, setAnchorElPricing] = useState(null);
  const navigate = useNavigate();
  const isOpen = (anchor) => Boolean(anchor);
  const location = useLocation();

  const PageHaveWhiteHeader = () => {
    return PagesWithWhiteHeader.some((page) => location.pathname.includes(page));
  };

  const isActualPage = (page) => {
    return location.pathname === page;
  };

  const openMenuPricing = (event) => {
    setButtonHover(true);
    setAnchorElPricing(event.currentTarget);
  };

  const closeMenuPricing = () => {
    setButtonHover(false);
    setAnchorElPricing(null);
  };

  return (
    <HeaderContainer>
      <PrimaryHeaderContainer whiteHeader={PageHaveWhiteHeader()}>
        <PrimaryHeaderContent>
          <LogoButton onClick={() => navigate('/')}>
            <img src={fixuplogo} alt="FixUP logo" height={25} />
          </LogoButton>
          <PrimaryHeaderTabsContainer>
            <StyledHeaderMenuItem href="/servicios" isActualPage={isActualPage('/servicios')}>
              Servicios
            </StyledHeaderMenuItem>
            <StyledHeaderMenuItem href="https://empresas.fixupweb.com" target="_blank">
              Servicios Corporativos
            </StyledHeaderMenuItem>
            <StyledHeaderMenuItem href="/reviews" isActualPage={isActualPage('/reviews')}>
              Reseñas
            </StyledHeaderMenuItem>
            <StyledHeaderMenuItem href="/sobre" isActualPage={isActualPage('/sobre')}>
              Sobre FixUP!
            </StyledHeaderMenuItem>
            <StyledHeaderMenuItem href="/contacto" isActualPage={isActualPage('/contacto')}>
              Contacto
            </StyledHeaderMenuItem>
            <StyledHeaderMenuItem href="https://blog.fixupweb.com/" target="_blank">
              Blog
            </StyledHeaderMenuItem>
          </PrimaryHeaderTabsContainer>
          <ButtonsContainer>
            <AccountCircleIcon
              sx={{ fontSize: 24, cursor: 'pointer' }}
              onClick={() => window.open('https://estado.fixupweb.com/estado', '_blank')}
            />
            <RegionButton />
            <PricingButton
              variant="contained"
              hover={buttonHover}
              onMouseEnter={(event) => openMenuPricing(event)}>
              Cotizar ahora
            </PricingButton>
          </ButtonsContainer>
        </PrimaryHeaderContent>
      </PrimaryHeaderContainer>
      <PricingMenuContainer
        anchorEl={anchorElPricing}
        open={isOpen(anchorElPricing)}
        onClose={closeMenuPricing}>
        <PricingMenuDropdown closeMenuPricing={closeMenuPricing} />
      </PricingMenuContainer>
    </HeaderContainer>
  );
};

export default DesktopHeader;

const PricingMenuContainer = muiStyled(Menu)(() => ({
  left: '-20%',
  top: '20px'
}));

const HeaderContainer = muiStyled('header')(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column'
}));

const LogoButton = muiStyled('div')(() => ({
  cursor: 'pointer'
}));

const StyledHeaderMenuItem = muiStyled(StyledMenuItem)(({ ...props }) => ({
  color: props.whiteHeader && 'white',
  fontWeight: 'bold',
  fontSize: 12,
  textDecoration: props.isActualPage && 'underline',
  '&:hover': {
    textDecoration: 'underline'
  }
}));

const ButtonsContainer = muiStyled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: 15
}));

const PrimaryHeaderTabsContainer = muiStyled('div')(() => ({
  display: 'flex',
  gap: '20px'
}));

const PrimaryHeaderContainer = muiStyled('div')(() => ({
  height: '65px',
  backgroundColor: 'white',
  position: 'fixed',
  top: '0px',
  boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
  width: '100%',
  zIndex: 1000,
  padding: 10
}));

const PrimaryHeaderContent = muiStyled('div')(() => ({
  maxWidth: '1200px',
  height: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  margin: 'auto',
  alignItems: 'center'
}));
