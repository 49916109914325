import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { isMobile } from 'utilities/utils';
import './App.css';
import ReviewsPage from 'pages/reseñas/ReviewsPage';
import Header from 'components/header/header';
import Footer from 'components/footer/footer';
import HomePage from 'pages/home/HomePage';
import SobrePage from 'pages/sobre/SobrePage';
import ServiciosPage from 'pages/servicios/ServiciosPage';
import ContactoPage from 'pages/contacto/ContactoPage';
import RepararPage from 'pages/reparar/RepararPage';
import ConfirmacionPage from './pages/confirmacion/ConfirmacionPage';
import RegionPage from './pages/region/RegionPage';
import PrivacidadPage from './pages/privacidad/PrivacidadPage';
import MapaDelSitioPage from './pages/mapa-del-sitio/MapaDelSitioPage';
import TerminosYCondicionesPage from './pages/terminos-y-condiciones/TerminosYCondicionesPage';
import theme from './styles/theme';
import commonActions from './redux/actions/common.actions';
import { useDispatch } from 'react-redux';
// import TopBanner from './components/top-banner/top-banner';

function App() {
  const [mobileView, setMobileView] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const banner = useSelector((state) => state.common.topBanner[0]);
  const pagesWithInfo = ['/', '/sobre', '/contacto'];
  useEffect(() => {
    const regionSelected = JSON.parse(localStorage.getItem('region-id'));
    if (!regionSelected) {
      navigate('/region', { state: { lastPage: window.location.pathname } });
      localStorage.setItem('show-onboarding', true);
    }
  }, []);
  useEffect(() => {
    const setResponsiveness = () => {
      return setMobileView(isMobile());
    };
    setResponsiveness();
    window.addEventListener('resize', () => setResponsiveness());
    return () => {
      window.removeEventListener('resize', () => setResponsiveness());
    };
  }, []);
  useEffect(() => {
    dispatch(commonActions.getTopBanner.request(window.location.pathname));
    dispatch(commonActions.getPromotionalBanner.request(window.location.pathname));
    const showOnboarding = JSON.parse(localStorage.getItem('show-onboarding'));
    if (showOnboarding) {
      if (pagesWithInfo.includes(location.pathname)) {
        localStorage.setItem('show-onboarding', false);
      }
    }
  }, [dispatch, window.location.pathname]);
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <div className="app">
          <Header mobileView={mobileView} />
          {/*{banner && <TopBanner banner={banner} />}*/}
          <div className="app-page-content">
            <Routes>
              <Route path={''} element={<HomePage />} />
              <Route path={'/region'} element={<RegionPage mobileView={mobileView} />} />
              <Route path={'/reviews'} element={<ReviewsPage mobileView={mobileView} />} />
              <Route path={'/home'} element={<HomePage />} />
              <Route path={'/sobre'} element={<SobrePage />} />
              <Route path={'/servicios'} element={<ServiciosPage />} />
              <Route path={'/contacto'} element={<ContactoPage />} />
              <Route path={'/reparar'}>
                <Route path={''} element={<RepararPage />} />
                <Route path={':device'} element={<RepararPage />} />
                <Route path={':device/:brand'} element={<RepararPage />} />
              </Route>
              <Route path={'/confirmacion'} element={<ConfirmacionPage />} />
              <Route path={'/privacidad'} element={<PrivacidadPage />} />
              <Route path={'/mapa-sitio'} element={<MapaDelSitioPage />} />
              <Route path={'/terminos-condiciones'} element={<TerminosYCondicionesPage />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </ThemeProvider>
    </>
  );
}

export default App;
