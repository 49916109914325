import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from '../../components/common/styled-span';
import COUNTRIES from '../../utilities/countries';
import StyledCard from '../../components/common/styled-card';
import { Grid } from '@mui/material';
import fixuplogo from 'assets/FIXUP-LOGO.svg';
import countriesBanner from 'assets/banners/countriesBanner.png';
import { useLocation, useNavigate } from 'react-router-dom';

const RegionPage = () => {
  let navigate = useNavigate();
  const lastLocation = useLocation()?.state?.lastPage ?? '/';
  const selectCountry = (country) => {
    if (country.available) {
      localStorage.setItem('region-id', country.id);
      localStorage.setItem('region-area-code', country.areaCode);
      navigate(lastLocation.toString());
    }
  };
  return (
    <PageContainer>
      <ContentContainer>
        <LogoContainer>
          <img loading="lazy" src={fixuplogo} height={50} alt="fixup" />
          <StyledSpan fontSize={24}>Elige tu país</StyledSpan>
        </LogoContainer>
        <Grid container spacing={4}>
          {COUNTRIES.map((country) => {
            const available = country.available;
            return (
              <Grid
                onClick={() => selectCountry(country)}
                key={country.id}
                container
                item
                xs={6}
                sm={3}
                md={3}
                lg={3}
                justifyContent="center">
                <CountryCard disabled={!available}>
                  <StyledSpan fontSize={10} fontWeight={400} sx={{ opacity: available && '0' }}>
                    Proximamente
                  </StyledSpan>
                  <img
                    loading="lazy"
                    src={country.image}
                    alt={country.name}
                    style={{ maxWidth: '70%', height: 'auto' }}
                  />
                  <StyledSpan fontSize={16} fontWeight={700}>
                    {country.name}
                  </StyledSpan>
                </CountryCard>
              </Grid>
            );
          })}
        </Grid>
      </ContentContainer>
    </PageContainer>
  );
};

export default RegionPage;

const CountryCard = muiStyled(StyledCard)(({ theme, ...props }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '15px',
    width: '120px',
    height: '150px',
    zIndex: 5,
    '&:hover': {
      border: props.disabled ? 'none' : `2px solid ${theme.palette.success.main}`,
      cursor: props.disabled ? 'default' : 'pointer'
    }
  };
});

const LogoContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '15px',
    zIndex: 5,
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center'
    }
  };
});

const PageContainer = muiStyled('div')(({ theme }) => {
  return {
    width: '100vw',
    height: '100vh',
    overflowX: 'hidden',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '30px',
    [theme.breakpoints.up('md')]: {
      backgroundImage: `url('${countriesBanner}')`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right',
      justifyContent: 'center'
    },
    [theme.breakpoints.down('sm')]: {}
  };
});

const ContentContainer = muiStyled('div')(() => {
  return {
    maxWidth: '900px',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '30px'
  };
});
