import { styled as muiStyled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { StyledSpan } from 'components/common/styled-span';
// import StyledButton from 'components/common/styled-button';
import smartphone from 'assets/devices/smartphone.png';
import { BENEFITS_SERVICES } from 'components/benefits/benefits';
import theme from '../../../../styles/theme';
// import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import ADDITIONALS from './additionals';
import { useSelector } from 'react-redux';
import { fullImageURL } from '../../../../utilities/utils';

const PricingStatus = ({ progress }) => {
  // const Additionals = ADDITIONALS[serviceSelected];
  const deviceType = useSelector((state) => state.pricing.device.type);
  const image = (deviceType?.icono && fullImageURL(deviceType.icono)) ?? smartphone;
  return (
    <ComponentContainer>
      <StepsContainer>
        <CircularProgressContainer>
          <CircularProgress variant="determinate" value={progress} size={55} color="success" />
          <ImageContainer>
            <img src={image} height={30} alt="device" />
          </ImageContainer>
        </CircularProgressContainer>
        {BENEFITS_SERVICES.map((benefit) => (
          <BenefitCard key={benefit.id}>
            <img src={benefit.image} height={16} alt="benefits" />
            <BenefitText>
              <StyledSpan fontSize={10} fontWeight={700} color={theme.palette.lightblue.main}>
                {benefit.primaryText}
              </StyledSpan>
              <StyledSpan fontSize={12} fontWeight={700} color={theme.palette.lightblue.main}>
                {benefit.secondaryText}
              </StyledSpan>
            </BenefitText>
          </BenefitCard>
        ))}
      </StepsContainer>
      {/*{Additionals && <Additionals />}*/}
      {/*<ContactContainer>*/}
      {/*  <StyledSpan fontWeight={700} fontSize={16} color="#A2A9CC">*/}
      {/*    Queremos apoyarte a tomar la mejor decisión.*/}
      {/*  </StyledSpan>*/}
      {/*  <StyledButton color="lightgray" variant="contained" fontcolor="white">*/}
      {/*    <WhatsAppIcon />*/}
      {/*    Hablá con un experto*/}
      {/*  </StyledButton>*/}
      {/*</ContactContainer>*/}
    </ComponentContainer>
  );
};

export default PricingStatus;

const BenefitText = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column'
  };
});

const BenefitCard = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.lightblue.main,
    alignItems: 'flex-start',
    gap: '5px',
    width: 'max-content'
  };
});

const StepsContainer = muiStyled('div')(() => {
  return {
    borderRadius: 10,
    border: '1px solid #A2A9CC',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
    gap: '15px',
    width: 340
  };
});

// const ContactContainer = muiStyled('div')(() => {
//   return {
//     height: 'max-content',
//     borderRadius: 10,
//     border: '1px solid #A2A9CC',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-around',
//     padding: '24px',
//     gap: '10px'
//   };
// });

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    height: 'max-content',
    maxWidth: '310px'
  };
});

const CircularProgressContainer = muiStyled('div')(() => {
  return {
    position: 'relative',
    display: 'inline-flex'
  };
});

const ImageContainer = muiStyled('div')(() => {
  return {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
});
