import { StyledStepContainer } from '../../../../components/styled-overlay';
import { StyledSpan, StyledTitle } from 'components/common/styled-span';
import { FormControl, Grid, RadioGroup } from '@mui/material';
import { SUCURSAL_DATES } from '../../../MOCKED/MOCKED_DATA';
import SelectRadio from 'components/select-radio/select-radio';
import theme from '../../../../../../styles/theme';
import { BottomBar } from '../../../../components/bottom-bar';
import { ContinueButton } from '../../../../components/buttons';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import pricingActions from 'redux/actions/pricing.actions';

const RemoteChooseDate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const selectDate = (e) => {
    setSelectedDate(SUCURSAL_DATES.filter((date) => date.title === e.target.value)[0]);
    setSelectedHour(null);
    dispatch(pricingActions.service.chooseHour(null));
  };
  const selectHour = (e) => {
    setSelectedHour(e.target.value);
    dispatch(pricingActions.service.chooseDate(selectedDate));
    dispatch(pricingActions.service.chooseHour(e.target.value));
  };
  return (
    <StyledStepContainer>
      <StyledTitle>¡Excelente! ¿Cuándo te gustaría que nos contactemos?</StyledTitle>
      <FormControl>
        <RadioGroup onChange={selectDate}>
          <Grid container spacing={2}>
            {SUCURSAL_DATES.map((date) => (
              <Grid item xs={12} md={6} lg={6} key={date.id}>
                <SelectRadio
                  value={date.title}
                  label={date.title}
                  checked={selectedDate === date}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
      </FormControl>
      {selectedDate && (
        <>
          <StyledSpan fontSize={16} fontWeight={700} color={theme.palette.lightblue.main}>
            ¿En qué franja horaria?
          </StyledSpan>
          <FormControl>
            <RadioGroup onChange={selectHour}>
              <Grid container spacing={2}>
                {selectedDate.hoursAvailable.map((date) => (
                  <Grid item xs={12} md={6} lg={6} key={date.id}>
                    <SelectRadio
                      value={date.title}
                      label={date.title}
                      checked={selectedHour === date.title}
                    />
                  </Grid>
                ))}
              </Grid>
            </RadioGroup>
          </FormControl>
        </>
      )}
      <BottomBar>
        <ContinueButton
          handleClick={() => navigate('/confirmacion')}
          disabled={!selectedHour || !selectedDate}>
          Continuar
        </ContinueButton>
      </BottomBar>
    </StyledStepContainer>
  );
};

export default RemoteChooseDate;
