import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from 'components/common/styled-span';
import { Button, Divider } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fullImageURL } from '../../../../utilities/utils';

const PricingMenu = ({ setStep, closeMenu }) => {
  let navigate = useNavigate();
  const devices = useSelector((state) => state.pricing.devices);
  const selectDevice = (device) => {
    navigate(`/reparar`, { state: { deviceState: device } });
    closeMenu();
  };
  return (
    <>
      <BackButton onClick={() => setStep(0)} variant="outlined">
        <ArrowBackIosIcon fontSize="10px" />
        VOLVER
      </BackButton>
      <DevicesContainer>
        <DevicesHeader>
          <StyledSpan color="white" fontSize={16} fontWeight={900}>
            Elegí tu equipo
          </StyledSpan>
        </DevicesHeader>
        {devices.map((device) => (
          <>
            <DeviceItem onClick={() => selectDevice(device)}>
              <StyledSpan fontSize={16} fontWeight={700}>
                {device.nombre}
              </StyledSpan>
              <img src={fullImageURL(device.icono)} height={30} width={30} alt="device type" />
            </DeviceItem>
            <Divider />
          </>
        ))}
      </DevicesContainer>
    </>
  );
};

export default PricingMenu;

const BackButton = muiStyled(Button)(({ theme }) => {
  return {
    borderRadius: 28,
    width: 'max-content',
    textTransform: 'none',
    fontWeight: 500,
    fontSize: 16,
    fontFamily: 'Inter',
    color: theme.palette.primary.main
  };
});

const DevicesContainer = muiStyled('div')(() => {
  return {
    borderRadius: 10,
    boxShadow:
      'rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px',
    display: 'flex',
    flexDirection: 'column'
  };
});

const DevicesHeader = muiStyled('div')(({ theme }) => {
  return {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    padding: '30px',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10
  };
});

const DeviceItem = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '64px',
    padding: '30px',
    '&:hover': {
      backgroundColor: theme.palette.white.main
    }
  };
});
