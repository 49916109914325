import { Grid } from '@mui/material';
import { StyledSpan } from 'components/common/styled-span';
import Theme from 'styles/theme';
import image from 'assets/banners/chair1.png';
import './initial-banner.scss';
import { isMobile } from '../../../../utilities/utils';

const InitialBanner = () => {
  return (
    <Grid container style={{ color: Theme.palette.primary.main, padding: 10 }} spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={6} display="flex" flexDirection="column" gap="20px">
        <StyledSpan fontSize={isMobile() ? 28 : 40} fontWeight={700}>
          No es sólo una reparación, es una persona feliz 😎
        </StyledSpan>
        <StyledSpan fontSize={isMobile() ? 20 : 24} fontWeight={400}>
          En FixUP! sabemos que detrás de cada reparación, hay mucho más que un dispositivo. Hay
          sueños que esperan ser cumplidos, mensajes que esperan ser enviados, llamadas con seres
          queridos que esperan poder volver a conectarse.
        </StyledSpan>
        <StyledSpan fontSize={isMobile() ? 20 : 24} fontWeight={400}>
          Con ese compromiso, trabajamos para que todos nuestros clientes tenga la mejor experiencia
          posible y constamente replantearnos cómo podemos hacerlo mejor
        </StyledSpan>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={6}
        lg={6}
        justifyContent={isMobile() ? 'right' : 'center'}>
        <div className="banner-image">
          <img
            src={image}
            alt="dude in a chair"
            width="100%"
            height="100%"
            style={{ maxHeight: isMobile() ? '500px' : '' }}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default InitialBanner;
