import './footer.scss';
import { FooterContent, FooterDiv } from './styled';
import { REPAIR_ITEMS } from './items';
import { PRIMARY_TABS } from '../header/items';
import { StyledMenuItem } from '../common/styled-menu-item';
import SocialNetwork from '../social-networks/social-networks';
import datafiscal from 'assets/footer/datafiscal.png';
import comprasegura from 'assets/footer/comprasegura.png';
import { Grid } from '@mui/material';
import fixuplogo from 'assets/FIXUP-LOGO-WHITE.svg';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { PageIncludedInList } from '../../utilities/utils';

const PagesWithoutFooter = ['/confirmacion', '/reparar', '/region'];

const Footer = () => {
  let location = useLocation();
  const regionSelected = JSON.parse(localStorage.getItem('region-id'));
  const regionText = {
    ['1']: 'WABRIT S.A.S CUIT 30-71662061-8 CABA | Argentina.',
    ['9']: 'NEWLE SpA RUT 77780036-1 Santiago | Chile.',
    ['8']: 'NEWGUAY S.A.S RUT 219305340011 Montevideo | Uruguay.'
  };
  useEffect(() => {}, [location]);
  return (
    !PageIncludedInList(window.location.pathname, PagesWithoutFooter) && (
      <FooterDiv>
        <FooterContent>
          <Grid container>
            <Grid container xs={12} sm={12} md={6} lg={6} justifyContent="center">
              <Grid item xs={1} sm={1} md={0} lg={0} />
              <Grid container xs={5} sm={5} md={3} lg={3}>
                <div className="items-list">
                  <span className="items-header"> Reparar </span>
                  <ul>
                    {REPAIR_ITEMS.map((item) => (
                      <li key={item.id} style={{ marginBottom: '10px' }}>
                        <StyledMenuItem color="#F3F4F8" fontSize={10} href={item.path}>
                          {item.title}
                        </StyledMenuItem>
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid container xs={5} sm={5} md={3} lg={3}>
                <div className="items-list">
                  <span className="items-header"> Accesos </span>
                  <ul>
                    {PRIMARY_TABS.map((item) => (
                      <li key={item.id} style={{ marginBottom: '10px' }}>
                        <StyledMenuItem color="#F3F4F8" fontSize={10} href={item.path}>
                          {item.title.toUpperCase()}
                        </StyledMenuItem>
                      </li>
                    ))}
                  </ul>
                </div>
              </Grid>
              <Grid item xs={1} sm={1} md={0} lg={0} />
            </Grid>
            <Grid container xs={12} sm={12} md={6} lg={6} justifyContent="center">
              <SocialNetwork />
            </Grid>
          </Grid>
          <div className="fixup-footer-mid">
            <div className="mid-content">
              <img src={fixuplogo} alt="fixup" />
              <div className="images">
                <a href="http://qr.afip.gob.ar/?qr=_6-GWNAJSlb9kuEVsmjuVQ,," target="_F960AFIPInfo">
                  <img src={datafiscal} alt="QR code" width={30} />
                </a>
                <img src={comprasegura} alt="Security" />
              </div>
            </div>
          </div>
          <div className="terms-and-conditions">
            <span className="top-text">
              © {new Date().getFullYear()} |{' '}
              <a href={'/terminos-condiciones'}>Términos y Condiciones </a>|{' '}
              <a href={'/privacidad'}>Privacidad </a>| <a href={'/mapa-sitio'}>Mapa de sitio</a>
            </span>

            <span className="bottom-text">
              <p>Todos los derechos reservados fixupweb.com. {regionText[regionSelected]}</p>
              <p>
                Los precios online y los planes de financiación para los productos
                presentados/publicados en fixupweb.com son válidos exclusivamente para la compra vía
                internet.
              </p>
              <p>
                La venta de cualquier producto o servicio publicado está sujeta a la verificación de
                stock y/o disponibilidad.
              </p>
            </span>
          </div>
        </FooterContent>
      </FooterDiv>
    )
  );
};

export default Footer;
