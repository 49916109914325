export const isMobile = () => {
  return window.innerWidth < 900;
};

export const isNullUndefEmptyStr = (object) => {
  return Object.values(object).some((value, key) => {
    if (key == 7) {
      return false;
    }
    return value === null || value === undefined || value === '';
  });
};

export const PageIncludedInList = (currentPage, list) => {
  return list.some((page) => currentPage.includes(page));
};

export const fullImageURL = (url) => {
  // eslint-disable-next-line no-undef
  return `${process.env.REACT_APP_STORAGE_URL}/${url}`;
};

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const isValidEmail = (value) => {
  let regex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
  return regex.test(value);
};

export const scrollToSectionSmoothly = (targetElementId, duration = 1000, hasNavbar = false) => {
  const targetElement = document.querySelector(targetElementId);
  const targetPosition = targetElement.offsetTop;
  const windowHeight = window.innerHeight;
  let startTime;

  function scroll(timestamp) {
    if (!startTime) {
      startTime = timestamp;
    }

    const timeElapsed = timestamp - startTime;
    const progress = Math.min(timeElapsed / duration, 1);
    const easeOutCubic = (t) => 1 - Math.pow(1 - t, 3);

    let scrollToY =
      targetPosition - (hasNavbar ? windowHeight / 5 - targetElement.clientHeight / 2 : 0);

    window.scrollTo(0, window.scrollY + (scrollToY - window.scrollY) * easeOutCubic(progress));

    if (timeElapsed < duration) {
      requestAnimationFrame(scroll);
    }
  }

  requestAnimationFrame(scroll);
};

export function formatNumber(number) {
  // Splitting the number into integer and decimal parts
  let parts = number?.toString()?.split('.');
  let integerPart = parts && parts[0];
  let decimalPart = parts?.length > 1 ? ',' + parts[1] : '';

  // Adding commas to separate thousands in the integer part
  integerPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Returning the formatted number
  return integerPart + decimalPart;
}
