import { styled as muiStyled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import TicketSVG from 'assets/FixUPPass.svg';
import FixUpLogo from 'assets/FIXUP-LOGO-WHITE.svg';
import { StyledSpan, StyledTitle } from '../common/styled-span';
import planeIcon from 'assets/planeIcon.svg';
import labelIcon from 'assets/labelIcon.svg';
import appointmentIcon from 'assets/appointmentIcon.svg';
import clientIcon from 'assets/clientIcon.svg';
import { Grid, Modal } from '@mui/material';
import StyledButton from '../common/styled-button';
import DownloadIcon from '@mui/icons-material/Download';
import { useCallback, useRef } from 'react';
import html2canvas from 'html2canvas';
import downloadjs from 'downloadjs';

const ServicePass = ({ data, open, handleClose }) => {
  const { QR, name, serviceCenter, appointment, clientNumber, applicationNumber } = data;
  const service_pass = useRef(null);
  const downloadImage = useCallback(async () => {
    const pass = document.getElementById('service_pass');
    const canvas = await html2canvas(pass, {
      allowTaint: false,
      useCORS: true,
      scale: 2
    });
    const dataURL = canvas.toDataURL('image/png');
    downloadjs(dataURL, `FixUP - ${applicationNumber}.png`, 'image/png');
  }, []);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <ModalContainer>
        <ServicePassContainer id="service_pass" ref={service_pass}>
          <CardContainer>
            <LeftSideContainer>
              <Header justifyContent="flex-start">
                <img src={TicketSVG} alt="ticket" />
                <StyledTitle color="white">Service Pass</StyledTitle>
              </Header>
              <DetailsContent>
                <StyledSpan fontWeight={700} fontSize={30}>
                  {name}
                </StyledSpan>
                <StyledSpan fontWeight={400} fontSize={14}>
                  Hacé una captura de pantalla o mostrá este QR en el centro de servicio.
                </StyledSpan>
                <ImageContainer src={QR} alt={'qr-code'} />
              </DetailsContent>
            </LeftSideContainer>
          </CardContainer>
          <CardContainer>
            <RightSideContainer>
              <Header>
                <img src={FixUpLogo} alt="fixup!" />
              </Header>
              <DetailsContent>
                <Grid container sx={{ height: '100%', display: 'flex', gap: '20px' }}>
                  <Grid item xs={5} sm={5} md={12}>
                    <ItemContainer>
                      <ItemTitle>
                        <ItemIcon src={planeIcon} />
                        <ItemTitleLabel>Centro de Servicio</ItemTitleLabel>
                      </ItemTitle>
                      <ItemValueLabel>{serviceCenter}</ItemValueLabel>
                    </ItemContainer>
                  </Grid>
                  <Grid item xs={5} sm={5} md={12}>
                    <ItemContainer>
                      <ItemTitle>
                        <ItemIcon src={labelIcon} />
                        <ItemTitleLabel>Número de solicitud</ItemTitleLabel>
                      </ItemTitle>
                      <ItemValueLabel>{applicationNumber}</ItemValueLabel>
                    </ItemContainer>
                  </Grid>
                  <Grid item xs={5} sm={5} md={12}>
                    <ItemContainer>
                      <ItemTitle>
                        <ItemIcon src={appointmentIcon} />
                        <ItemTitleLabel>Turno</ItemTitleLabel>
                      </ItemTitle>
                      <ItemValueLabel>{appointment}</ItemValueLabel>
                    </ItemContainer>
                  </Grid>
                  <Grid item xs={5} sm={5} md={12}>
                    <ItemContainer>
                      <ItemTitle>
                        <ItemIcon src={clientIcon} />
                        <ItemTitleLabel>Número de cliente</ItemTitleLabel>
                      </ItemTitle>
                      <ItemValueLabel>{clientNumber}</ItemValueLabel>
                    </ItemContainer>
                  </Grid>
                </Grid>
              </DetailsContent>
            </RightSideContainer>
          </CardContainer>
        </ServicePassContainer>
        <StyledButton variant="contained" color="blue" fontcolor="white" onClick={downloadImage}>
          <DownloadIcon /> Descargar{' '}
        </StyledButton>
      </ModalContainer>
    </Modal>
  );
};

export default ServicePass;

const ModalContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '25px',
    alignItems: 'flex-end'
  };
});

const ServicePassContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    width: 'max-content',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    },
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    }
  };
});

const CardContainer = muiStyled(Card)(() => {
  return {
    borderRadius: '10px',
    backgroundColor: 'white',
    maxWidth: '350px',
    height: '100%'
  };
});

const LeftSideContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  };
});

const DetailsContent = muiStyled('div')(({ theme, ...props }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: props.alignItems ?? 'center',
    gap: props.gap ?? '5px',
    padding: 20,
    height: '350px',
    [theme.breakpoints.down('xs')]: {
      height: 'max-content'
    }
  };
});

const RightSideContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse'
    },
    height: '100%'
  };
});

const ItemContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px'
  };
});

const ItemTitle = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '5px'
  };
});

const ItemTitleLabel = muiStyled(StyledSpan)(({ theme }) => {
  return {
    color: theme.palette.lightblue.main,
    fontSize: 12,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10
    }
  };
});

const ItemValueLabel = muiStyled(StyledSpan)(({ theme }) => {
  return {
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      fontSize: 20
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  };
});

const ItemIcon = muiStyled('img')(() => {
  return {
    height: '15px',
    color: 'red'
  };
});

const Header = muiStyled('div')(({ theme, ...props }) => {
  return {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    alignItems: 'center',
    height: '50px',
    flexDirection: 'row',
    justifyContent: props.justifyContent ?? 'center',
    gap: 30,
    padding: 10,
    width: '100%'
  };
});

const ImageContainer = muiStyled('img')(({ theme }) => {
  return {
    [theme.breakpoints.down('xs')]: {
      height: '100px'
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '200px'
    }
  };
});
