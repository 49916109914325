import DeviceCard from '../../../device-card/device-card';
import { Grid } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
const PricingMenuDropdown = ({ closeMenuPricing }) => {
  const devices = useSelector((state) => state.pricing.devices);
  let navigate = useNavigate();
  const selectDevice = (device) => {
    navigate(`/reparar`, { state: { deviceState: device } });
    closeMenuPricing();
  };
  return (
    <MenuContainer onMouseLeave={closeMenuPricing}>
      <Grid container spacing={1}>
        {devices.map((device) => (
          <Grid key={device.id} item md="auto" onClick={() => selectDevice(device)}>
            <DeviceCard device={device} />
          </Grid>
        ))}
      </Grid>
    </MenuContainer>
  );
};

export default PricingMenuDropdown;

const MenuContainer = muiStyled('div')(() => ({
  width: '550px',
  padding: '20px'
}));
