import { StyledStepContainer } from '../../../../components/styled-overlay';
import { StyledSpan, StyledTitle } from 'components/common/styled-span';
import { FormControl, Grid, RadioGroup, Stack } from '@mui/material';
import SelectRadio from 'components/select-radio/select-radio';
import { useState } from 'react';
import theme from 'styles/theme';
import { ContinueButton } from '../../../../components/buttons';
import { BottomBar, HolderBottomBar } from '../../../../components/bottom-bar';
import { useDispatch, useSelector } from 'react-redux';
import pricingActions from 'redux/actions/pricing.actions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CustomDatePicker from 'components/date-picker/date-picker';
import { useNavigate } from 'react-router-dom';
import { scrollToSectionSmoothly } from 'utilities/utils';

const TakeitChooseDate = () => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const selectedSucursal = useSelector((state) => state.pricing.service.sucursal);
  const derivacionId = useSelector((state) => state.pricing.derivacion_id);
  const selectedService = useSelector((state) => state.pricing.service.type);
  const firstDates = selectedSucursal.sucursalHorarioFranja.filter(
    (element) => element?.franja_horaria
  );
  const navigate = useNavigate();

  const handleChange = (newValue) => {
    const formattedDate = newValue?.format('YYYY-MM-DD');
    setSelectedDate(formattedDate);
    setSelectedHour(null);
    // scroll
    scrollToSectionSmoothly('#time', 1000);
  };

  const selectDate = (e) => {
    setSelectedDate(e.target.value);
    setSelectedHour(null);
    dispatch(pricingActions.service.chooseHour(null));
    // scroll
    scrollToSectionSmoothly('#time', 1000);
  };
  const selectHour = (e) => {
    setSelectedHour(e.target.value);
    dispatch(pricingActions.service.chooseDate(selectedDate));
    dispatch(pricingActions.service.chooseHour(e.target.value));
  };

  const handleButton = () => {
    dispatch(
      pricingActions.postEndDerivacion.request({
        derivacion_id: derivacionId,
        company_id: JSON.parse(localStorage.getItem('region-id')),
        fecha: selectedDate,
        franja_horaria_id: parseInt(selectedHour),
        sucursal_id: selectedSucursal.id,
        servicio_id: selectedService
      })
    );
    window.scrollTo({ top: 0, behavior: 'smooth' });
    navigate('/confirmacion');
  };
  return (
    <StyledStepContainer>
      <StyledTitle>¡Excelente! ¿Cuándo preferis ir?</StyledTitle>
      <FormControl>
        <RadioGroup onChange={selectDate}>
          <Grid container spacing={2}>
            {firstDates.slice(0, 5).map((date) => (
              <Grid key={date.id} item xs={12} md={6} lg={6}>
                <SelectRadio
                  value={date.fecha}
                  label={`${date.fecha_texto.charAt(0).toUpperCase()}${date.fecha_texto.slice(1)}`}
                  checked={selectedDate === date.fecha}
                  withDatePicker={true}
                />
              </Grid>
            ))}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={6}>
                <CustomDatePicker
                  value={
                    firstDates
                      .slice(5)
                      .map((element) => element?.fecha)
                      .find((date) => date === selectedDate)
                      ? selectedDate
                      : null
                  }
                  onChange={handleChange}
                  availableDates={firstDates.slice(5).map((element) => element?.fecha)}
                  checked={
                    firstDates
                      .slice(5)
                      .map((element) => element?.fecha)
                      .find((date) => date === selectedDate)
                      ? selectedDate
                      : false
                  }
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </RadioGroup>
      </FormControl>
      <Stack id="time">
        {selectedDate && (
          <>
            <StyledSpan fontSize={16} fontWeight={700} color={theme.palette.lightblue.main}>
              ¿En qué franja horaria?
            </StyledSpan>
            <FormControl>
              <RadioGroup onChange={selectHour}>
                <Grid container spacing={2}>
                  {firstDates
                    .filter((date) => date.fecha === selectedDate)[0]
                    ?.franja_horaria.map((hour) => (
                      <Grid key={hour.id} item xs={12} md={6} lg={6}>
                        <SelectRadio
                          value={hour.id}
                          label={hour.label}
                          checked={selectedHour == hour.id}
                          withDatePicker={true}
                        />
                      </Grid>
                    ))}
                </Grid>
              </RadioGroup>
            </FormControl>
          </>
        )}
      </Stack>
      <HolderBottomBar />
      <BottomBar>
        <ContinueButton handleClick={handleButton} disabled={!selectedHour || !selectedDate}>
          Continuar
        </ContinueButton>
      </BottomBar>
    </StyledStepContainer>
  );
};

export default TakeitChooseDate;
