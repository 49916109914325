import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from 'components/common/styled-span';
import StyledCard from '../../../../../../components/common/styled-card';
import VerifiedIcon from '@mui/icons-material/Verified';
import joystick from 'assets/devices/joystick.png';
import laptop from 'assets/devices/laptop.png';
import printer from 'assets/devices/printer.png';
import smartphone from 'assets/devices/smartphone.png';
import smartTV from 'assets/devices/smartTV.png';
import smartWatch from 'assets/devices/smartWatch.png';
import tablet from 'assets/devices/tablet.png';
import { formatNumber, fullImageURL } from 'utilities/utils';
import { useSelector } from 'react-redux';

const ServicePricingCard = ({ service, onClick, selected }) => {
  const { titulo, subtitulo, precio, caracteristicas, mensaje, icono } = service;
  const deviceType = useSelector((state) => state.pricing.device.type);

  const types = {
    tablet: tablet,
    'consolas-de-juego': joystick,
    computadora: laptop,
    Impresora: printer,
    smartphone: smartphone,
    'smart-tv': smartTV,
    smartwatch: smartWatch
  };

  function validation(value) {
    if (value in types) {
      return types[value];
    }

    return joystick;
  }
  // const recommended = true;
  return (
    <CardContainer disableHover onClick={onClick} selected={selected}>
      <ImageContainer image={icono ? fullImageURL(icono) : validation(deviceType?.url_amigable)}>
        {/* {recommended && (
          <RecommendedLabel>
            <StyledSpan color="#005F21" fontSize={12} fontWeight={700}>
              Recomendada por FixUP!
            </StyledSpan>
            <VerifiedIcon style={{ height: 12, color: '#005F21' }} />
          </RecommendedLabel>
        )} */}
      </ImageContainer>
      <PriceContainer>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <StyledSpan fontSize={16} fontWeight={700}>
            <div dangerouslySetInnerHTML={{ __html: titulo }} />
          </StyledSpan>
          <VerifiedIcon style={{ height: 16, color: '#3BB164' }} />
        </div>
        <StyledSpan fontSize={24} fontWeight={700}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ fontWeight: 500 }}
              dangerouslySetInnerHTML={{ __html: precio ? formatNumber(precio) : '' }}
            />
          </div>
        </StyledSpan>
        {subtitulo && (
          <StyledSpan fontSize={12} fontWeight={700} color={'#005F21'}>
            <div dangerouslySetInnerHTML={{ __html: formatNumber(subtitulo) }} />
          </StyledSpan>
        )}
        <div
          style={{ color: '#646A7E', fontSize: '14px', fontWeight: 500 }}
          dangerouslySetInnerHTML={{ __html: caracteristicas }}
        />
      </PriceContainer>
      <DescriptionContainer>
        <div>
          <StyledSpan color="#ffffff" fontSize={16} fontWeight={700}>
            <div dangerouslySetInnerHTML={{ __html: mensaje }} />
          </StyledSpan>
        </div>
      </DescriptionContainer>
    </CardContainer>
  );
};

export default ServicePricingCard;

const CardContainer = muiStyled(StyledCard)(({ theme, ...props }) => {
  return {
    borderRadius: '12px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '250px',
    scale: props.selected && '1.02',
    outline: props.selected && '3px solid #6CE194',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      height: 'max-content'
    },
    cursor: 'pointer',
    '&:hover': {
      filter: 'hue-rotate(15deg);'
    }
  };
});

const ImageContainer = muiStyled('div')(({ theme, ...props }) => {
  return {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '12px',
    backgroundColor: '#3872E5',
    backgroundImage: `url('${props.image}')`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: 300
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 200
    }
  };
});

const PriceContainer = muiStyled('div')(() => {
  return {
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '8px',
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 0,
    height: '100%',
    width: '100%'
  };
});

const DescriptionContainer = muiStyled('div')(({ theme }) => {
  return {
    padding: '24px',
    backgroundColor: theme.palette.lightblue.main,
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 0,
    height: '100%',
    width: '100%'
  };
});

// const RecommendedLabel = muiStyled('div')(() => {
//   return {
//     position: 'relative',
//     top: 0,
//     width: '100%',
//     height: 30,
//     backgroundColor: '#A9DF7E',
//     borderTopRightRadius: '12px',
//     borderTopLeftRadius: '12px',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center'
//   };
// });
