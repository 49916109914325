import { Grid } from '@mui/material';
import ReasonCard from './reason-card';
import { REASONS } from './reasons';
import TextContainer from 'components/common/styled-text-section';
import { StyledSpan } from 'components/common/styled-span';
import banner from 'assets/why-choose-fixup/banner.png';
import StyledButton from 'components/common/styled-button';
import {
  ComponentContainer,
  ComponentContent,
  GridContent,
  ImageContainer
} from './styled-components';
import StyledSection from '../common/styled-section';
import { isMobile } from '../../utilities/utils';
import { useNavigate } from 'react-router-dom';

const WhyChooseFixup = () => {
  const navigate = useNavigate();
  return (
    <ComponentContainer>
      <StyledSection margin="auto">
        <TextContainer>
          <div style={{ display: 'flex', gap: '5px' }}>
            <StyledSpan fontWeight="bold" fontSize="24px">
              Por qué elegirnos
            </StyledSpan>
          </div>
          <StyledSpan fontWeight={300} fontSize="16px">
            Estamos transformando la experiencia de reparar cualquier dispositivo, en algo 100%
            digital, amigable, confiable y seguro.
          </StyledSpan>
        </TextContainer>
        <ComponentContent>
          <GridContent>
            <Grid container justifyContent={isMobile() && 'center'} spacing={2}>
              {REASONS.map((reason) => (
                <Grid
                  key={reason.id}
                  container
                  item
                  xs={12}
                  sm={4}
                  justifyContent={isMobile() && 'center'}>
                  <ReasonCard reason={reason} />
                </Grid>
              ))}
            </Grid>
            <StyledButton
              color="secondary"
              variant="contained"
              onClick={() => navigate(`/reparar`)}>
              Cotiza tu reparación ahora
            </StyledButton>
          </GridContent>
          <ImageContainer>
            <img src={banner} alt="banner" height="100%" />
          </ImageContainer>
        </ComponentContent>
      </StyledSection>
    </ComponentContainer>
  );
};

export default WhyChooseFixup;
