import { styled as muiStyled } from '@mui/material/styles';
import { Modal } from '@mui/material';
import { StyledTitle } from 'components/common/styled-span';
import StyledButton from 'components/common/styled-button';
import banner from 'assets/questionsModalImage.png';

const QuestionsModal = ({ open, handleClose, handleContinueButton, handlePassButton }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: { backgroundColor: 'rgba(89, 99, 148, 0.8)', backdropFilter: 'blur(2px)' }
      }}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <div>
        <ModalContainer>
          <ImageContainer>
            <img src={banner} alt={'Questions banner'} />
          </ImageContainer>
          <StyledTitle style={{ fontWeight: 400 }} textalign={'center'}>
            Te haremos algunas <b>preguntas extra</b> para una cotización más exacta.
          </StyledTitle>
          <ButtonsContainer>
            <StyledButton
              variant="contained"
              color="white"
              fontcolor="#596394"
              onClick={handlePassButton}>
              Saltar
            </StyledButton>
            <StyledButton
              variant="contained"
              color="lightblue"
              fontcolor="white"
              onClick={handleContinueButton}>
              ¡Entendido!
            </StyledButton>
          </ButtonsContainer>
        </ModalContainer>
      </div>
    </Modal>
  );
};

export default QuestionsModal;

const ModalContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: 30,
    padding: 30,
    maxWidth: 400,
    minHeight: 390,
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(189,216,255,1) 100%)',
    borderRadius: 10,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      maxWidth: 'unset'
    }
  };
});

const ImageContainer = muiStyled('div')(({ theme }) => {
  return {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  };
});

const ButtonsContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    width: '100%',
    gap: 10
  };
});
