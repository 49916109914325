import { styled as muiStyled } from '@mui/material/styles';
import { StyledSpan } from 'components/common/styled-span';
import StyledCard from '../common/styled-card';
import { fullImageURL } from '../../utilities/utils';

const ModelCard = ({ model, defaultImage }) => {
  const { imagen, nombre } = model;
  return (
    <ComponentContainer>
      <img src={imagen ? fullImageURL(imagen) : defaultImage} height={100} alt="device" />
      <StyledHr />
      <StyledSpan fontSize={12} fontWeight={300}>
        {nombre}
      </StyledSpan>
    </ComponentContainer>
  );
};

export default ModelCard;

const StyledHr = muiStyled('hr')(() => {
  return {
    display: 'block',
    height: '1px',
    border: 0,
    borderTop: '1px solid #A2A9CC',
    margin: '1em 0',
    padding: 0,
    width: '140px'
  };
});

const ComponentContainer = muiStyled(StyledCard)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '5px',
    height: '200px',
    width: '140px',
    borderRadius: 10,
    border: '1px solid #A2A9CC',
    backgroundColor: 'white'
  };
});
