import { AppBar, Drawer, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import fixuplogo from 'assets/FIXUP-LOGO.svg';
import { useState } from 'react';
import Menu from './menu/menu';
import theme from '../../../styles/theme';
import { useNavigate } from 'react-router-dom';

const MobileHeader = () => {
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const tablet = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const closeMenu = () => {
    setAnchorElMenu(null);
  };

  const isOpen = (anchor) => Boolean(anchor);

  return (
    <AppBar position="fixed">
      <Toolbar style={{ background: 'white', display: 'flex', gap: '20px', width: '100vw' }}>
        <IconButton
          onClick={() => setAnchorElMenu(true)}
          {...{
            edge: 'start',
            'aria-label': 'menu',
            'aria-haspopup': 'true'
          }}>
          <MenuIcon />
        </IconButton>
        <div onClick={() => navigate('')}>
          <img src={fixuplogo} alt="FixUP logo" height={25} />
        </div>
      </Toolbar>
      <Drawer
        anchor="left"
        open={isOpen(anchorElMenu)}
        onClose={closeMenu}
        onOpen={isOpen(anchorElMenu)}
        PaperProps={{
          style: {
            width: tablet ? '60%' : '100%'
          }
        }}>
        <Menu closeMenu={closeMenu} />
      </Drawer>
    </AppBar>
  );
};

export default MobileHeader;
