import { styled as muiStyled } from '@mui/material/styles';
import StyledCard from '../../common/styled-card';

const PartnerCard = ({ image }) => (
  <CardContainer disableHover>
    <img src={image} alt="partner" width={'80%'} />
  </CardContainer>
);

export default PartnerCard;

const CardContainer = muiStyled(StyledCard)(() => {
  return {
    borderRadius: '10px',
    padding: '10px',
    height: 120,
    width: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  };
});
