import { StyledSpan } from '../common/styled-span';
import { styled as muiStyled } from '@mui/material/styles';

const StepComponent = ({ step }) => {
  const { title, image, description } = step;
  return (
    <ComponentContainer>
      <ImageContainer>
        <img src={image} alt="step" height={64} width={64} />
      </ImageContainer>
      <StyledSpan fontWeight="bold" fontSize={12} textalign="center">
        {title}
      </StyledSpan>
      <StyledSpan fontWeight={400} fontSize={12} textalign="center">
        {description}
      </StyledSpan>
    </ComponentContainer>
  );
};

const ComponentContainer = muiStyled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '155px',
    gap: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      gap: '10px'
    }
  };
});

const ImageContainer = muiStyled('div')(() => {
  return {
    backgroundColor: 'white',
    borderRadius: '50px',
    width: '70px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px'
  };
});

export default StepComponent;
