import { styled as muiStyled } from '@mui/material/styles';
import TextContainer from '../common/styled-text-section';
import { StyledSpan, StyledTitle } from '../common/styled-span';
import StyledButton from '../common/styled-button';
import { fullImageURL, isMobile } from '../../utilities/utils';
import { useNavigate } from 'react-router-dom';

const PromotionalBanner = ({ data }) => {
  const navigate = useNavigate();
  const { background_color, cta, cta_url, nombre, image, descripcion } = data;
  return (
    <BannerContainer backgroundColor={background_color} image={fullImageURL(image)}>
      <BannerContent>
        <TextContainer>
          <StyledTitle color="#fff" fontSize={isMobile() ? 32 : 48}>
            {nombre}
          </StyledTitle>
          <StyledSpan color="#fff" fontWeight={400} fontSize={isMobile() ? 28 : 48}>
            {descripcion}
          </StyledSpan>
        </TextContainer>
        {cta && (
          <StyledButton color="secondary" variant="contained" onClick={() => navigate(cta_url)}>
            {cta}
          </StyledButton>
        )}
      </BannerContent>
    </BannerContainer>
  );
};

export default PromotionalBanner;

const BannerContainer = muiStyled('div')(({ theme, ...props }) => {
  return {
    // backgroundColor: props.backgroundColor ?? theme.palette.background,
    background: props.image && `url('${props.image}')`,
    backgroundSize: props.image && 'cover',
    backgroundPosition: props.image && 'center center',
    backgroundRepeat: props.image && 'no-repeat',
    width: '100vw',
    height: 560,
    display: 'flex',
    justifyContent: 'center',
    padding: 30,
    [theme.breakpoints.up('sm')]: {
      padding: 120
    }
  };
});

const BannerContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100%',
    maxWidth: '1200px',
    width: '100%'
  };
});
