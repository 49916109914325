import { styled as muiStyled } from '@mui/material/styles';
import fixuplogo from 'assets/FIXUP-LOGO.svg';
import { useMediaQuery } from '@mui/material';
import theme from 'styles/theme';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import PrimaryMenu from './primary-menu';
import PricingMenu from './pricing-menu';
import { useNavigate } from 'react-router-dom';

const Menu = ({ closeMenu }) => {
  const [step, setStep] = useState(0);
  const navigate = useNavigate();
  const mobile = useMediaQuery(theme.breakpoints.down('xs'));
  const CloseButton = () => <CloseIcon onClick={closeMenu} />;
  return (
    <MenuContainer>
      <MenuHeader>
        <div onClick={() => navigate('/')}>
          <img src={fixuplogo} alt="FixUP logo" height={25} />
        </div>

        {mobile && <CloseButton />}
      </MenuHeader>
      <MenuContent>
        {step === 0 ? (
          <PrimaryMenu setStep={setStep} />
        ) : (
          <PricingMenu setStep={setStep} closeMenu={closeMenu} />
        )}
      </MenuContent>
    </MenuContainer>
  );
};

export default Menu;

const MenuHeader = muiStyled('div')(() => {
  return {
    position: 'fixed',
    top: '0px',
    backgroundColor: 'white',
    height: '64px',
    width: '100%',
    boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px',
    zIndex: 10
  };
});

const MenuContent = muiStyled('div')(() => {
  return {
    margin: '84px 0 10px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    padding: '0 20px 0 20px'
  };
});

const MenuContainer = muiStyled('div')(({ theme }) => {
  return {
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    zIndex: 100,
    position: 'absolute'
  };
});
