import { styled as muiStyled } from '@mui/material/styles';
import StyledCard from '../../../../../../components/common/styled-card';
import { StyledSpan } from '../../../../../../components/common/styled-span';
import { useState } from 'react';
import { Popover } from '@mui/material';
import { formatNumber, fullImageURL } from 'utilities/utils';

const DeliverTypeCard = ({ props }) => {
  const { titulo_form, fixup_icono, descripcion_form, costo_envio, id } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  // const handlePopoverOpen = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const atHomeValidation = () => {
    if (id == 7) {
      if (costo_envio == 0) {
        return 'Retiro gratis en tu zona';
      } else {
        return `Costo de envío: $${formatNumber(costo_envio)}`;
      }
    }

    return descripcion_form;
  };

  return (
    <>
      <CardContainer scale={1}>
        <TopContent>
          <img src={fullImageURL(fixup_icono)} alt={titulo_form} height={64} />
          <StyledSpan fontSize={16} fontWeight={700}>
            {titulo_form}
          </StyledSpan>
        </TopContent>
        <BottomContent
        //  onMouseEnter={handlePopoverOpen}
        //  onMouseLeave={handlePopoverClose}
        >
          <StyledSpan fontSize={12} fontWeight={500} color="#ffffff">
            {atHomeValidation()}
          </StyledSpan>
        </BottomContent>
      </CardContainer>
      <Popover
        sx={{
          pointerEvents: 'none',
          marginTop: '20px'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        <PopoverContent>{/* <span>sdadsa</span> */}</PopoverContent>
      </Popover>
    </>
  );
};

export default DeliverTypeCard;

const CardContainer = muiStyled(StyledCard)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid #A2A9CC',
    height: '100%'
  };
});

const TopContent = muiStyled('div')(() => {
  return {
    display: 'flex',
    width: '100%',
    gap: 10,
    alignItems: 'center',
    padding: 18
  };
});

const BottomContent = muiStyled('div')(() => {
  return {
    backgroundColor: '#3872E6',
    padding: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  };
});

const PopoverContent = muiStyled('div')(({ theme }) => {
  return {
    backgroundColor: theme.palette.lightblue.main,
    padding: 18,
    display: 'flex',
    flexDirection: 'column',
    gap: 10
  };
});
