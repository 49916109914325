import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import google from '../../assets/googleForStartups.png';
import microsoft from '../../assets/microsoftForStartups.png';

import './social-networks.scss';
import { Stack, Typography } from '@mui/material';

const SocialNetwork = () => (
  <div className="social-network-container">
    <Typography
      sx={{
        '@media (max-width: 900px)': {
          textAlign: 'center'
        }
      }}
      className="social-text">
      ¡Sigamos en contacto!
    </Typography>
    <Stack
      className="icons"
      sx={{
        '@media (max-width: 900px)': {
          justifyContent: 'center'
        }
      }}>
      <FontAwesomeIcon
        icon={faInstagram}
        size="lg"
        onClick={() => window.open('https://www.instagram.com/somosfixup/', '_blank')}
      />
      <FontAwesomeIcon
        icon={faFacebookF}
        size="lg"
        onClick={() => window.open('https://www.facebook.com/somosfixup', '_blank')}
      />
      <FontAwesomeIcon
        icon={faYoutube}
        size="lg"
        onClick={() =>
          window.open('https://www.youtube.com/channel/UCRMW4RNmrtpS-7Get4-Oecw', '_blank')
        }
      />
      <FontAwesomeIcon
        icon={faLinkedinIn}
        size="lg"
        onClick={() => window.open('https://ar.linkedin.com/company/somosfixup', '_blank')}
      />
    </Stack>
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        gap: '1rem',
        marginTop: '1.8rem',
        '@media (max-width: 900px)': {
          flexDirection: 'row'
        }
      }}>
      <img src={microsoft} alt="Microsoft for startups" width={100} />
      <img src={google} alt="Google for startups" />
    </Stack>
  </div>
);

export default SocialNetwork;
