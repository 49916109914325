import { useDispatch, useSelector } from 'react-redux';
import { styled as muiStyled } from '@mui/material/styles';
import ContactBanner from 'components/contact-banner/contact-banner';
import FixUpInMediaSection from 'components/media/fix-up-in-media-section/fix-up-in-media-section';
// import NumbersSpeakForUs from 'components/numbers-speak-for-us/numbers-speak-for-us';
import { PageContainer } from 'components/common/styled-page-container';
import WhyChooseFixup from 'components/why-choose-fixup/why-choose-fixup';
import HowFixupWorks from 'components/how-fixup-works/how-fixup-works';
import commonActions from 'redux/actions/common.actions';
import StyledSection from 'components/common/styled-section';
import StyledButton from 'components/common/styled-button';
import TextContainer from 'components/common/styled-text-section';
import { StyledDescription, StyledTitle } from 'components/common/styled-span';
import BrandCard from 'components/brands/brand-card/brand-card';
import { Grid } from '@mui/material';
// import DeviceCard from 'components/device-card/device-card';
import ServiceCard from 'components/service-card/service-card';
import CustomCarousel from 'components/carousel/carousel';
import ReviewCard from 'components/reviews/review-card/review-card';
import BenefitsComponent from 'components/benefits/benefits-component';
import { useRef, useEffect } from 'react';
import AlwaysCloseComponent from '../../components/always-close-component/always-close-component';
import SearchBanner from './components/search-banner';
import { useNavigate } from 'react-router-dom';
import { fullImageURL } from '../../utilities/utils';
// import pricingActions from '../../redux/actions/pricing.actions';

import map from 'assets/services/Maps.png';
import packageImage from 'assets/services/Package.png';
import conference from 'assets/services/Conference.png';
import microscope from 'assets/services/Microscope.png';
import PromotionalBanner from '../../components/promotional-banner/promotional-banner';
// import LoaderComponent from 'components/loader/loader-component';
import DeviceStep from 'pages/reparar/steps/device-step/device-step';

const SERVICES = [
  {
    id: 1,
    image: map,
    title: 'Vos lo traes',
    description: '¡Hay muchos puntos en todo el país!',
    isFeatured: false,
    path: '/servicios#i-take-it'
  },
  {
    id: 2,
    image: packageImage,
    title: 'Lo retiramos de tu casa',
    description: '¡Lo buscamos y lo devolvemos \n' + 'como nuevo!',
    isFeatured: true,
    path: '/servicios#at-home'
  },
  {
    id: 3,
    image: conference,
    title: 'Nos conectamos',
    description: 'Nuestros especialistas\n' + 'te asisten por videollamada.',
    isFeatured: false,
    path: '/servicios#remote'
  },
  {
    id: 4,
    image: microscope,
    title: 'Lo haces vos',
    description: 'Te enviamos un KIT de reparación "Hágalo usted mismo"',
    isFeatured: false,
    path: '/servicios#do-it-yourself'
  }
];

const HomePage = () => {
  const reviewsCarouselRef = useRef(null);
  const servicesCarouselRef = useRef(null);
  const reviews = useSelector((state) => state.common.reviews);
  const officialBrands = useSelector((state) => state.common.officialBrands);
  // const devicesState = useSelector((state) => state.pricing.devices);
  const media = useSelector((state) => state.common.fixUpInMedia);
  const sucursalesCercanas = useSelector((state) => state.common.sucursalesCercanas);
  const promotionalBanner = useSelector((state) => state.common.promotionalBanner);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const devices = useMemo(() => devicesState, [devicesState]);

  useEffect(() => {
    // dispatch(pricingActions.getDevices.request());
    dispatch(commonActions.getReviews.request());
    dispatch(commonActions.getFixUpInMedia.request());
    dispatch(commonActions.getSucursalesCercanas.request());
    dispatch(commonActions.getOfficialBrands.request());
  }, [dispatch]);

  return (
    <PageContainer>
      <SearchBanner />
      {/* DEVICES SECTION */}
      <BenefitsComponent />
      <StyledSection>
        <DeviceStep />
        {/* <TextContainer>
          <StyledTitle>¿Qué necesitas reparar?</StyledTitle>
        </TextContainer> */}
        <ComponentContainer>
          {/* <Grid container spacing={2} justifyContent="center">
            {devices?.length ? (
              devices?.map((device) => (
                <Grid
                  key={device?.id}
                  item
                  xs={'auto'}
                  onClick={() =>
                    navigate(`/reparar/${device?.url_amigable}`, { state: { deviceState: device } })
                  }>
                  <DeviceCard device={device} />
                </Grid>
              ))
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  height: '100% !important',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flex: 1
                }}>
                <LoaderComponent />
              </div>
            )}
          </Grid> */}
          <StyledButton color="secondary" variant="contained" onClick={() => navigate(`/reparar`)}>
            Ver todos los equipos
          </StyledButton>
        </ComponentContainer>
      </StyledSection>
      {/* REVIEWS SECTION */}
      {reviews && (
        <StyledSection>
          <TextContainer>
            <StyledTitle>Opiniones 100% verificadas</StyledTitle>
          </TextContainer>
          <ComponentContainer>
            <CustomCarousel loop reff={reviewsCarouselRef} renderArrow={() => <></>}>
              {reviews.map((review) => (
                <ReviewCard key={review.id} review={review} />
              ))}
            </CustomCarousel>
            <StyledButton
              color="secondary"
              variant="contained"
              onClick={() => navigate(`/reviews`)}>
              Ver más opiniones
            </StyledButton>
          </ComponentContainer>
        </StyledSection>
      )}
      {promotionalBanner.length > 0 && <PromotionalBanner data={promotionalBanner[0]} />}
      <HowFixupWorks />
      {/* SERVICES SECTION */}
      <StyledSection>
        <TextContainer>
          <StyledTitle>Un servicio para cada necesidad.</StyledTitle>
        </TextContainer>
        <ComponentContainer>
          <CustomCarousel loop reff={servicesCarouselRef} sm={2} md={2}>
            {SERVICES.map((service) => (
              <ServiceCard key={service.id} service={service} />
            ))}
          </CustomCarousel>
          <StyledButton
            color="secondary"
            variant="contained"
            onClick={() => navigate(`/servicios`)}>
            Ver todos los servicios
          </StyledButton>
        </ComponentContainer>
      </StyledSection>
      {sucursalesCercanas.length > 0 && <AlwaysCloseComponent sucursales={sucursalesCercanas} />}
      {/* BRANDS SECTION */}
      <StyledSection>
        <TextContainer>
          <StyledTitle>Reparamos todas las marcas, todas las fallas</StyledTitle>
          <StyledDescription>Trabajamos con estas y muchas marcas más.</StyledDescription>
        </TextContainer>
        <ComponentContainer>
          <Grid container spacing={1} justifyContent="center">
            {officialBrands.map((brand) => (
              <Grid item xs={'auto'} key={brand.id}>
                <BrandCard brandImage={fullImageURL(brand.logo_marca)} />
              </Grid>
            ))}
          </Grid>
          <StyledButton color="secondary" variant="contained" onClick={() => navigate(`/reparar`)}>
            Buscá tu equipo
          </StyledButton>
        </ComponentContainer>
      </StyledSection>
      <WhyChooseFixup />
      {/* <NumbersSpeakForUs /> */}
      <FixUpInMediaSection media={media} />
      <ContactBanner />
    </PageContainer>
  );
};

const ComponentContainer = muiStyled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '50px',
    height: '100%'
  };
});

export default HomePage;
