import { StyledSpan } from 'components/common/styled-span';
import Theme from 'styles/theme';
import { styled as muiStyled } from '@mui/material/styles';
import StyledCard from '../../common/styled-card';
import { fullImageURL, openInNewTab } from '../../../utilities/utils';

const MediaCard = ({ media }) => {
  return (
    <MediaCardContainer onClick={() => openInNewTab(media.url)}>
      <img src={fullImageURL(media.image)} alt="media" width="100%" style={{ maxHeight: '75px' }} />
      <StyledSpan
        fontWeight={400}
        textalign="center"
        fontSize={12}
        color={Theme.palette.primary.main}>
        {media.nombre}
      </StyledSpan>
    </MediaCardContainer>
  );
};

export default MediaCard;

const MediaCardContainer = muiStyled(StyledCard)(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 120,
    width: 200,
    alignItems: 'center',
    gap: 5,
    padding: 10,
    borderRadius: 10
  };
});
